.figures {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  margin-bottom: 30px;
}

.subscription-figures {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  margin-bottom: 30px;
}

.card-figures {
  display: flex;
  flex-wrap: wrap;
  width: '500px';
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 4px;
  border-width: 30px;
  border-radius: 5px;
  color: #515459;
  text-align: center;
  border: 1.5px solid #243b55;
  box-shadow: 0px 0px 20px -11px rgba(0, 0, 0, 0.75);
  background: #ffffff;
}

.bank-transfer-figures {
  display: flex;
  flex-wrap: wrap;
  width: '500px';
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 4px;
  border-width: 30px;
  color: #515459;
  text-align: center;
  box-shadow: 0px 0px 20px -11px rgba(0, 0, 0, 0.75);
  border: 1.5px solid rgb(192, 144, 221);
  border-radius: 5px;
  background: #ffffff;
}

.cash-figures {
  display: flex;
  flex-wrap: wrap;
  width: '500px';
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 4px;
  border-width: 30px;
  border-radius: 5px;
  color: #515459;
  border: 1.5px solid rgb(36, 220, 137);
  text-align: center;
  box-shadow: 0px 0px 20px -11px rgba(0, 0, 0, 0.75);
  background: #ffffff;
}

.zelle-figures {
  display: flex;
  flex-wrap: wrap;
  width: '500px';
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 4px;
  border-width: 30px;
  border-radius: 5px;
  color: #515459;
  border: 1.5px solid #681acd;
  text-align: center;
  box-shadow: 0px 0px 20px -11px rgba(0, 0, 0, 0.75);
  background: #ffffff;
}

.plei-figures {
  display: flex;
  flex-wrap: wrap;
  width: '500px';
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 4px;
  border-width: 30px;
  border-radius: 5px;
  color: #515459;
  border: 1.5px solid #16775d;
  text-align: center;
  box-shadow: 0px 0px 20px -11px rgba(0, 0, 0, 0.75);
  background: #ffffff;
}

.stripe-figures {
  display: flex;
  flex-wrap: wrap;
  width: '500px';
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 4px;
  border-width: 30px;
  border-radius: 5px;
  color: #515459;
  text-align: center;
  border: 1.5px solid rgb(84, 105, 212);
  box-shadow: 0px 0px 20px -11px rgba(0, 0, 0, 0.75);
  background: #ffffff;
}

.stripe-subscriptions-figures {
  display: flex;
  flex-wrap: wrap;
  width: '500px';
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 4px;
  border-width: 30px;
  border-radius: 5px;
  color: #515459;
  text-align: center;
  border: 1.5px solid rgb(158, 208, 233);
  box-shadow: 0px 0px 20px -11px rgba(0, 0, 0, 0.75);
  background: #ffffff;
}

.total-sum-figures {
  display: flex;
  flex-wrap: wrap;
  width: '500px';
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 4px;
  border-width: 30px;
  border-radius: 5px;
  color: #fff;
  text-align: center;
  border: 1.5px solid rgb(36, 220, 137);
  box-shadow: 0px 0px 20px -11px rgba(0, 0, 0, 0.75);
  background: #24dc89;
}

.figures-row {
  display: flex;
  flex: 1; /* added */
}

.figures-item {
  flex: 1; /* added */
}

.amount {
  margin: auto;
  font-size: 3.2vh;
}
.total {
  width: 100%;
  margin: auto;
  font-size: 1.5vh;
}

.stripe-amount {
  margin: auto;
  font-size: 3.2vh;
}
.card-amount {
  margin: auto;
  font-size: 3.2vh;
}

.cash-amount {
  margin: auto;
  font-size: 3.2vh;
}
.cash-total {
  width: 100%;
  margin: auto;
  font-size: 1.5vh;
}

.site-selection {
  display: flex;
  margin-top: 20px;
  margin-left: 40px;
}

.table-section {
  display: flex;
  background: #fafbff;
  padding: 2rem;
}

.cashTag {
  text-decoration: none;
  color: #ffffff;
  text-align: center;
  border-radius: 3px;
  background-color: #24dc89;
  border: 1px solid #21c079;
  padding-left: 12px;
  padding-right: 12px;
  margin: auto;
}
.zelleTag {
  text-decoration: none;
  color: #ffffff;
  text-align: center;
  border-radius: 3px;
  background-color: #681acd;
  border: 1px solid #681acd;
  padding-left: 12px;
  padding-right: 12px;
  margin: auto;
}
.pleiTag {
  text-decoration: none;
  color: #ffffff;
  text-align: center;
  border-radius: 3px;
  background-color: #16775d;
  border: 1px solid #16775d;
  padding-left: 12px;
  padding-right: 12px;
  margin: auto;
}
.bankTransferTag {
  text-decoration: none;
  color: #ffffff;
  text-align: center;
  border-radius: 3px;
  background-color: #c090dd;
  border: 1px solid #c090dd;
  padding-left: 12px;
  padding-right: 12px;
  margin: auto;
}

.cardTag {
  text-decoration: none;
  color: #ffffff;
  text-align: center;
  border-radius: 3px;
  background-color: #243b55;
  border: 1px solid #1b2026;
  padding-left: 12px;
  padding-right: 12px;
  margin: auto;
}
.stripeTag {
  text-decoration: none;
  color: #ffffff;
  text-align: center;
  border-radius: 3px;
  background-color: #5469d4;
  border: 1px solid rgb(65, 82, 165);
  padding-left: 12px;
  padding-right: 12px;
  margin: auto;
}
.stripeSubscriptionTag {
  text-decoration: none;
  color: #3f535d;
  text-align: center;
  border-radius: 3px;
  background-color: #cee7f4;
  border: 1px solid #3f535d;
  max-width: 70px;
  padding-left: 12px;
  padding-right: 12px;
  margin: auto;
}
.stripeMembershipTag {
  text-decoration: none;
  color: #161618;
  text-align: center;
  border-radius: 3px;
  background-color: #fc7cc967;
  border: 1px solid #fc7cc8;
  max-width: 70px;
  padding-left: 12px;
  padding-right: 12px;
  margin: auto;
}

.loading-screen {
  margin: 200px auto;
  width: 100px;
}

.grow {
  transition: all 0.2s ease-in-out;
}
.grow:hover {
  transform: scale(1.15);
}

.growSmall {
  transition: all 0.2s ease-in-out;
}
.growSmall:hover {
  transform: scale(1.015);
  background-color: #f3f3f3;
}

@media print {
  .fixtureList {
    background-color: white;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    padding: 15px;
    font-size: 14px;
    line-height: 18px;
  }
  .excludeFromPrint {
    display: none !important;
  }
}
