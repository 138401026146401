.MuiButton-contained {
    box-shadow: none !important;
    /* background-color: #f0f0f0 !important; */
}

#accessRestrictionsTableContainer {
    width:100%!important;
}

.textBox{
    background-color: #f0f0f0;
    border: none;
}

.rc-time-picker-input{
    border: none;
    border-bottom: 1px solid #333;
    border-radius: 0%;
    font-size: 16px;

}
.rc-time-picker-panel{
    z-index: 10002 !important;
}
.MuiToggleButton-root.Mui-selected {
    color: #fff !important;
    background-color: #4581E2 !important;
}

.closureTag {
    color: #a5a5a5;
    border: 1px solid #a5a5a5;
    text-align: center;
    border-radius: 3px;
    background-color: #f0f0f0;
    min-width: 125px;
}
.membershipsTag {
    color: #C090DD;
    border: 1px solid #C090DD;
    text-align: center;
    border-radius: 3px;
    background-color: #efe3f7;
    min-width: 125px;
    margin: 5px;
    cursor: pointer;
}
.subscriptionTag {
    color: #9ed0e9;
    border: 1px solid #9ed0e9;
    text-align: center;
    border-radius: 3px;
    background-color: #eaf8ff;
    min-width: 125px;
}
.reservationTag {
    color: #80c486;
    border: 1px solid #80c486;
    text-align: center;
    border-radius: 3px;
    background-color: #BFF2C4;
    min-width: 125px;
}

.MuiTablePagination-input{
    max-width: 100px;
}

.FAS-root{
        width: 100%;
        max-width: 360;
        background-color: #fff;
        position: relative;
        overflow: auto;
        max-height: 600;
}

.MuiListSubheader-root{
    color: black !important;
}

.rc-time-picker-clear-icon {
    display:none;
}