.dashboard-last-updated {
    margin-bottom: 1rem;
}

.dashboard-main {
    padding: 20px;
    background-color: #fafbff;
    min-height: 93vh;
}

.dashboard-title {
    font-size: 18px;
    font-weight: 700;
    /* padding-bottom: 40px; */
    display: flex;
    margin: 15px;
    width: 280px;
}

.dashboard-totals-title {
    font-size: 22px;
    font-weight: 700;
    padding-left: 20px;
    display: flex;
    width: 280px;
}

.dashboard-sub-title {
    font-size: 16px;
    padding-bottom: 10px;
}

.dashboard-blocks {
    display: flex;
    margin-bottom: 20px;
}

.dashboard-blocks-ind {
    margin: 5px;
    border: 1px solid #eee;
    border-radius: 15px;
    background-color: #fff;
}

.dashboard-block-title {
    display: flex;
    flex-direction: row;
}

.dashboard-block-content {
    overflow-y: scroll;
    max-height: 80vh;
    margin: 15px;
}

.dashboard-card {
    display: flex;
    flex-wrap: nowrap;
    margin: 10px;
    width: 280px;
    font-weight: 600;
    background: linear-gradient(145deg, #fafafa 0%, #f3f3f3 100%);
    border: 2px solid #eee;
    border-radius: 15px;
}

.dashboard-card-contents {
    margin: 20px;
}

.dashboard-card-title {
    font-weight: 700;
}

.dashboard-card-paymentstatus {}

.paid {
    color: rgb(9, 172, 36);
}

.unpaid {
    color: rgb(172, 9, 9);
}

.invoice {
    color: rgb(9, 47, 172);
}

.not-charged {
    color: rgb(9, 47, 172);
}

.dashboard-subsriptions-card {
    display: flex;
    flex-wrap: nowrap;
    margin: 10px;
    width: 280px;
    font-weight: 600;
    background: linear-gradient(145deg, rgba(191, 231, 251, 1) 0%, rgba(225, 244, 254, 1) 100%);
    border: 2px solid #bfe7fb;
    border-radius: 15px;
}

.dashboard-subsriptions-card-contents {
    margin: 20px;
}

.dashboard-subsriptions-card-title {}

.dashboard-subsriptions-card-paymentstatus {
    /* color: rgb(9, 172, 36); */
}

.dashboard-bookings-today {
    border-radius: 20px;
    padding: 20px;
    width: 150px;
    height: 150px;
    color: #515459;
    background-color: #fff;
    border: 2px solid #1dcc7d;
    background: linear-gradient(145deg, rgba(191, 251, 211, 0.3) 0%, rgba(243, 255, 247, 0.3) 100%);
    text-align: center;
    margin-right: 20px;
    box-shadow: 0px 0px 20px -11px rgba(0, 0, 0, 0.75);
}

.dashboard-bookings-today-number {
    font-size: 42px;
    font-weight: 800;
}

.dashboard-subscriptions-today {
    border-radius: 20px;
    padding: 20px;
    width: 150px;
    height: 150px;
    color: #515459;
    background-color: #fff;
    border: 2px solid #abd6eb;
    background: linear-gradient(145deg, rgba(191, 231, 251, 0.3) 0%, rgba(240, 250, 255, 0.3) 100%);
    box-shadow: 0px 0px 20px -11px rgba(0, 0, 0, 0.75);
    text-align: center;
    margin-right: 20px;
}

.dashboard-subscriptions-today-number {
    font-size: 42px;
    font-weight: 800;
    color: #515459;
}

.dashboard-cancellations-today {
    border-radius: 20px;
    padding: 20px;
    width: 150px;
    height: 150px;
    color: #515459;
    background-color: #fff;
    background: linear-gradient(145deg, rgba(255, 186, 186, 0.3) 0%, rgba(255, 221, 221, 0.3) 100%);
    border: 2px solid #f0b4b4;
    text-align: center;
    margin-right: 20px;
    box-shadow: 0px 0px 20px -11px rgba(0, 0, 0, 0.75);
}

.dashboard-cancellations-today-number {
    font-size: 42px;
    font-weight: 800;
}

.dashboard-subscriptions-unpaid {
    border-radius: 20px;
    padding: 20px;
    width: 150px;
    height: 150px;
    color: #515459;
    background-color: #fff;
    background: linear-gradient(145deg, rgba(255, 186, 186, 0.3) 0%, rgba(255, 221, 221, 0.3) 100%);
    border: 2px solid #f0b4b4;
    text-align: center;
    margin-right: 20px;
    box-shadow: 0px 0px 20px -11px rgba(0, 0, 0, 0.75);
}

.dashboard-subscriptions-unpaid-number {
    font-size: 42px;
    font-weight: 800;
}

.dashboard-tooltip-wrapper {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    padding-right: 20px;
}

.live {
    background: black;
    border-radius: 50%;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    margin: 8.5px;
    height: 15px;
    width: 15px;
    transform: scale(1);
    animation: pulse-black 2s infinite;
}

.live.green {
    background: #24dc89;
    box-shadow: 0 0 0 0 #24dc89;
    animation: pulse-green 2s infinite;
    margin-top: 5px;
    margin-bottom: -10px;
}

@keyframes pulse-green {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(51, 217, 178, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(51, 217, 178, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(51, 217, 178, 0);
    }
}