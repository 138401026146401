.grid {
  display: grid;
}

.flexFlexible {
  display: flex;
  flex-direction: column-reverse;
}

.section {
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

/* On medium screens */
@media (min-width: 768px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .flexFlexible {
    flex-direction: row;
  }
}

/* On large screens */
@media (min-width: 1024px) {
  .grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* On extra large screens */
@media (min-width: 1320px) {
  .grid {
    grid-template-columns: repeat(4, 1fr);
  }
}
