.booking-view-container {
    margin: 20px;
    display: flex;
    flex-wrap: wrap;
}
.booking-view-header {
    width: 100%;
}
.booking-view-body {
    display: flex;
    width: 100%;
}
