.keys {
  margin-right: 10px;
  margin-left: 30px;
  width: 20px;
  height: 20px;
}
.activeKey {
  background-color: #2edc89;
  border-radius: 5px;
}
.inactiveKey {
  background-color: #ac372f;
  border-radius: 5px;
}
.inProgressKey {
  background-color: #fb923c;
  border-radius: 5px;
}

.total-figures {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  width: 18%;
  margin: 20px;
  padding: 20px;
  border-width: 30px;
  border-radius: 5px;
  color: #515459;
  background: #fff;
  border: 2px solid #2edc89;
  border-radius: 20px;
  box-shadow: 0px 0px 20px -11px rgba(0, 0, 0, 0.75);
}

.total-subscriptions-figures {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  width: 18%;
  margin: 20px;
  padding: 20px;
  border-width: 30px;
  border-radius: 5px;
  color: #515459;
  background: #fff;
  border: 2px solid #abd6eb;
  border-radius: 20px;
  box-shadow: 0px 0px 20px -11px rgba(0, 0, 0, 0.75);
}

.total-subscriptions-active-figures {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  width: 18%;
  margin: 20px;
  padding: 20px;
  border-width: 30px;
  border-radius: 5px;
  color: #515459;
  background: #fff;
  border: 2px solid rgb(84, 105, 212);
  border-radius: 20px;
  box-shadow: 0px 0px 20px -11px rgba(0, 0, 0, 0.75);
}

.total-subscriptions-inactive-figures {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  width: 18%;
  margin: 20px;
  padding: 20px;
  border-width: 30px;
  border-radius: 5px;
  color: #515459;
  background: #fff;
  border: 2px solid #ac372f;
  border-radius: 20px;
  box-shadow: 0px 0px 20px -11px rgba(0, 0, 0, 0.75);
}
.total-subscriptions-offline-figures {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  width: 18%;
  margin: 20px;
  padding: 20px;
  border-width: 30px;
  border-radius: 5px;
  color: #515459;
  background: #fff;
  border: 2px solid #24dc89;
  border-radius: 20px;
  box-shadow: 0px 0px 20px -11px rgba(0, 0, 0, 0.75);
}

.total-amount {
  margin: auto;
  font-size: 2.2vh;
}
.total-subscriptions-amount {
  margin: auto;
  font-size: 2.2vh;
}
.total-subscriptions-active-amount {
  margin: auto;
  font-size: 2.2vh;
}
.total-subscriptions-inactive-amount {
  margin: auto;
  font-size: 2.2vh;
}

.subscription-view-card {
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0;
  padding: 20px;
  border-width: 30px;
  border-radius: 10px;
  color: #515459;
  text-align: center;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.break {
  flex-basis: 100%;
  height: 0;
}
