.successTag {
  color: #00573e;
  border: 1px solid #00573e;
  text-align: center;
  border-radius: 3px;
  background-color: #bff2c4;
  width: 125px;
}

.failTag {
  color: #a21b41;
  border: 1px solid #a21b41;
  text-align: center;
  border-radius: 3px;
  background-color: #ffded9;
  width: 125px;
}

.voidTag {
  color: #444c5f;
  border: 1px solid #444c5f;
  text-align: center;
  border-radius: 3px;
  background-color: #dee4eb;
  width: 125px;
}

.pendingTag {
  color: #9ed0e9;
  border: 1px solid #9ed0e9;
  text-align: center;
  border-radius: 3px;
  background-color: #eaf8ff;
  width: 125px;
}
