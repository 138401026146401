.header-logo {
  display: flex;
  padding-bottom: 30px;
  padding-left: 4px;
  padding-right: 10px;
  max-width: 100%;
  align-content: center;
}

.sign-up-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  align-items: center;
}

.text-field {
  margin-right: 0;
}

.signup-button {
  background-color: #24dc89 !important;
  color: #fff !important;
  width: 100%;
  height: 40px;
  display: inherit;
  align-items: inherit;
  justify-content: inherit;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 0.875rem;
  border: none;
  border-radius: 5px;
}

.flexContainer {
  display: "flex";
  flex-direction: "column";
  align-items: "center";
  justify-content: "center";
}

.container {
  margin-top: 80;
  padding: 30;
}

.mobileContainer {
  margin-top: 20;
  padding: 20;
  width: "100%";
}

.contentContainer {
  width: 500;
}

.mobileContent {
  width: "100%";
}

.avatarTitle {
  display: "flex";
  flex-direction: "column";
  align-items: "center";
  justify-content: "flex-start";
}

.nameTitle {
  display: "flex";
  flex-direction: "column";
  align-items: "center";
  justify-content: "center";
  margin-bottom: 30;
}

.sectionTitleContainer {
  display: "flex";
  align-items: "flex-end";
  justify-content: "flex-start";
}

.buttonEnd {
  margin-left: "auto";
}

.accountInfoContainer {
  padding: "100px";
  width: 500px; 
}

.error-message-section {
  text-align: center;
  display: flex;
  justify-content: center;
}

.background {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-position: bottom;
}

.accountCreatedConfirmation {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #24dc89;
  color: #fff;
  padding: 10px;
}

.signupCreatedSteps {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: top;
}

.signupCreatedStepsText {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: left;
  padding-left: 35px;
}

.largeIcon {
  width: 60px;
  height: 60px;
  padding-left: 15px;
}

.sign-up-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 100px;
  padding-bottom: 20px;
  align-items: center;
}
