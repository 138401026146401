.billingContainer {
  margin: 0 auto;
  justify-content: center;
  text-align: center;
  width: 70%;
  max-width: 800px;
  padding: 20px;
  padding-top: 80px;
  }

.billingPriceTable {
  margin: 0 auto;
  width: 95%;
}

.billingLogosBox {
  display: flex;
  justify-content: space-around;
}

.billingLogo {
  height: 150px;
}

.billingPaymentBoxBody {
  padding:0px 20px 20px 20px;
}

.checkoutStripeLogo {
  height: 75px;
  margin-top: 10px;
}

.stripePaymentButton {
  width: 100%;
  height: 60px;
  font-size: 20px !important;
}

.stripeCardElementBox {
  padding-left: 80px;
  padding-right: 80px;
}

.billingLoadingOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 999999;
  margin: auto;
}

.billingLoadingOverlay div {
  color: white;
  width: auto;
  height: 230px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

@media screen and (max-width: 650px) {
  .billingContainer {
    margin: 0 auto;
    justify-content: center;
    text-align: center;
    width: 95%;
    max-width: 800px;
    padding: 20px;
    padding-top: 80px;
    }

    .checkoutStripeLogo {
      height:auto;
      width:80%;
    }

    .stripePaymentButton {
      font-size: 16px !important;
    }

    .stripeCardElementBox {
      padding-left: 0;
      padding-right: 0;
    }

    .billingLogosBox {
      display: flex;
      justify-content: space-between;
    }

    .billingLogo {
      height: 100px;
    }
}