
/* TabbedNavigation.js  */

.tabbed-navigation-tabs-wrapper {
  margin:auto;
  text-align:center;
  width:55%;
}

.tabbed-navigation-tabs {
  margin:auto;
  width:350px;
}

.tabbed-navigation-tabs-wrapper .MuiTabs-fixed {
  width:fit-contents;
}

.tabbed-navigation-tabs-wrapper .autocomplete-textBox .MuiInputBase-root {
  margin:auto;
}

.tabbed-navigation-tabs-body {
  margin:auto;
  padding-top:20px;
}

.tabbed-navigation-tabs button{
  border:1px solid #f0f0f0;
  border-radius:3px;
  background-color:#f0f0f0;
  text-transform: none!important;
  font-size:15px;
}

.tabbed-navigation-tabs button:nth-child(1){
  margin-right:10px;
}

.tabbed-navigation-tabs button:nth-child(2){
  margin-left:10px;
}

.tabbed-navigation-tabs .MuiTabs-indicator {
  display:none;
}

.tabbed-navigation-tabs .Mui-selected {
  border:1px solid #4781e2;
  background-color:white;;
}

@media screen and (max-width: 888px) {
  .tabbed-navigation-tabs-wrapper {
    width:100%;
  }
  .tabbed-navigation-tabs {
    margin:auto;
    width:100%;
  }
}

/* CustomTextBoxWithLabel.js  */

.custom-text-box-wrapper {
  padding:10px;
  width:50%;
  display:inline-block;
}

.custom-text-box-wrapper .invalid {
  border:1px solid red;
}

.custom-text-box-wrapper p {
  margin:0 0 10px 0!important;
  text-align: left;
}

.custom-text-box-wrapper input {
  padding:15px 15px;
  width:100%;
  border:none;
  border-radius:5px;
  background-color: #F5F5F5;
  font-size:14px;
}

@media screen and (max-width: 888px) {
  .custom-text-box-wrapper {
    width:100%;
  }
}

/* Auto Complete.js  */

@media screen and (max-width: 888px) {
  .auto-complete-combo {
    width:100%!important;
  }
}