.rulesTag {
  color: #1691ce;
  border: 1px solid #1691ce;
  text-align: center;
  border-radius: 3px;
  background-color: #f5fcffff;
  min-width: 125px;
  margin: 5px;
  cursor: default;
  padding-left: 5px;
  padding-right: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.membershipsTag {
  color: #c090dd;
  border: 1px solid #c090dd;
  text-align: center;
  border-radius: 3px;
  background-color: #efe3f7;
  min-width: 125px;
  margin: 5px;
  cursor: pointer;
  padding-left: 5px;
  padding-right: 10px;
}
