.markdown h1 {
  font-size: 3rem;
  font-weight: 700;
  letter-spacing: -0.1rem;
  line-height: 1;
  padding-bottom: 0.75rem;
}

.markdown h2 {
  font-size: 2.5rem;
  font-weight: 700;
  letter-spacing: -0.1rem;
  line-height: 1;
  padding-bottom: 0.75rem;
}

.markdown h3 {
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: -0.1rem;
  line-height: 1;
  padding-bottom: 0.75rem;
}

.markdown h4 {
  font-size: 1.75rem;
  font-weight: 700;
  letter-spacing: -0.1rem;
  line-height: 1;
  padding-bottom: 0.75rem;
}

.markdown h5 {
  font-size: 1.5rem;
  font-weight: 700;
  letter-spacing: -0.1rem;
  line-height: 1;
  padding-bottom: 0.75rem;
}

.markdown h6 {
  font-size: 1.25rem;
  font-weight: 700;
  letter-spacing: -0.1rem;
  line-height: 1;
  padding-bottom: 0.75rem;
}

.markdown p {
  margin: 1rem 0;
}

.markdown a {
  color: '#0070f3';
  font-weight: 500;
  text-decoration: none;
}

.markdown ul {
  margin: 1rem 0;
}

.markdown li {
  margin: 0.5rem 0;
  margin-left: 2rem;
  padding-left: 0.5rem;
  list-style: disc;
}

/* On Small Screens */
@media (max-width: 640px) {
  .markdown h1 {
    font-size: 2.1rem;
  }

  .markdown h2 {
    font-size: 1.9rem;
  }

  .markdown h3 {
    font-size: 1.7rem;
  }

  .markdown h4 {
    font-size: 1.5rem;
  }

  .markdown h5 {
    font-size: 1.3rem;
  }

  .markdown h6 {
    font-size: 1.1rem;
  }

  .markdown p {
    margin: 0.75rem 0;
    font-size: 0.9rem;
  }
}
