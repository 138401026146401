.container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1rem;
  max-width: 100rem;
  margin: 0 auto;
}

.card {
  padding: 0.75rem;
  border: 1px solid #eaeaea;
  border-radius: 0.5rem;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
}

.span-2 {
  grid-column: span 2;
}

.span-3 {
  grid-column: span 3;
}

.subscription__header {
  display: flex;
  align-items: center;
}

.subscription__header__title {
  font-size: 1.1rem;
  font-weight: 600;
}

.subscription__header__value {
  margin-left: auto;
}


/** Medium Screen **/
@media (max-width: 1300px) {
  .grid {
    grid-template-columns: 1fr;
  }

  .span-2, .span-3 {
    grid-column: span 1;
  }
}