:root {
  --primary: 47, 255, 179;
  --primary-foreground: 30, 41, 59;
  --primary-light: 55, 250, 178;
  --primary-dark: 36, 242, 167;

  --accent: #6699FF;
  --destructive: #ac372f;
  --muted: #e0e0e0;

  --error: var(--destructive);
}

*, *::before, *::after {
  font-family: "Montserrat", sans-serif;
  box-sizing: border-box;
}

.App {
  text-align: center;
}

body {
  margin: 0px;
}

.reservation-creation-new-user-wrapper {
  display: table;
  flex-wrap: wrap;
  justify-content: center;
  margin: auto;
}
.cell-detail-pane {
  padding: 10px;
  width: 20%;
}

.nodeNameBase {
  transform: rotate(-10deg) !important;
}

.reservation-creation-modal-wrapper {
  border: 1px solid black;
  padding: 30px;
}
.Mui-selected {
  color: var(--accent) !important;
  background: none !important;
}
.MuiListItem-root {
  background-color: none !important;
}

.reservation-creation-stepper {
  height: 100%;
}

.reservation-creation-stepper-header {
  display: flex;
  justify-content: space-between;
}

.eservation-creation-stepper-header p {
  display: inline-flex;
}

.reservation-creation-stepper-header .MuiTypography-root:nth-child(1) {
  font-size: 16px;
}

.reservation-creation-stepper-header .MuiTypography-root:nth-child(2) {
  font-weight: bold;
  margin-left: auto;
  margin-left: 50px;
}

.reservation-creation-stepper-body {
  height: 80%;
  margin-top: 10px;
  overflow-y: scroll;
  border: 1px solid #eeeeee;
  padding: 10px;
}
.reservation-creation-stepper-add-bookings {
  margin: 0 auto !important;
  display: block !important;
  margin-top: 10px !important;
}

.reservation-creation-timetable {
  display: flex;
  gap: .5rem;
}

.reservation-creation-timetable-picker {
  margin-right: 20px;
  min-width: 850px;
}

.reservation-creation-timetable-basket {
  margin-top: 30px;
  padding: 10px;
  max-width: 500px;
  display: flex;
  flex-direction: column;
}
.reservation-creation-stepper-navigation {
  position: relative;
  width: 100%;
  margin-top: 10px;
}

.reservation-creation-type-button-wrapper {
  margin-top: 100px;
  width: 100%;
  text-align: center;
  margin: auto;
  display: block !important;
}

.reservation-creation-type-button {
  margin-bottom: 30px !important;
  width: 25%;
  background-color: #f9f9fb !important;
  border: 1px solid #f9f9fb !important;
  font-weight: 400 !important;
  text-transform: none !important;
  padding: 15px 20px !important;
  border-radius: 8px !important;
  color: grey !important;
}
.rc-time-picker-icon,
.rc-time-picker-clear {
  display: none;
}
.facility-picker-wrapper {
  display: flex;
  gap: .5rem;
  margin-top: 1rem;
}
.confirmedCard {
  border: 2px solid #e0e0e0;
  border-radius: 5px;
  width: 25%;
  margin-bottom: 30px;
  background: #f5f5f5;
  opacity: 0.75;
}
.confirmedCardDetails {
  width: 60%;
  padding: 10px;
  display: inline-block;
  padding-right: 0;
  background: white;
  border-radius: 5px;
}
.cancelledCardDetails {
  width: 90%;
  padding: 10px;
  display: inline-block;
  padding-right: 0;
  background: white;
  border-radius: 5px;
  border: 1px solid grey;
  margin: 5px;
}
.confirmedCardImageContainer {
  display: inline-block;
  background-size: 300px 100px cover;
  background-size: cover;
  background-position: center bottom;
  object-fit: cover;
  height: 120px;
  width: 40%;
  border-radius: 5px;
}
.confirmedCardImageContainer:before {
  content: "";
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}
.confirmedCardImage {
  width: 100%;
  height: 100%;
  float: right;
  display: block;
  object-fit: cover;
}

.reservation-creation-type-button:hover {
  border: 1px solid #a7c4e6 !important;
}

.loadingOverlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  vertical-align: middle;
  width: 100%;
  height: 50%;
  min-height: 400px;
  background-color: rgba(0, 0, 0, 0);
  z-index: 999999;
  margin: "auto";
}

#age-group-selector {
  width: 300px;
  display: flex !important;
  flex-direction: row;
}
.SingleDatePicker_picker__portal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.SingleDatePicker_picker__fullScreenPortal {
  background-color: #fff;
  z-index: 10 !important;
}
.DateRangePicker_picker {
  z-index: 11 !important;
}
.rc-time-picker-panel-select:first-child {
  margin-left: -1px !important;
}
/* .MuiDrawer-paper{
    position: relative !important; //hold menu drawer to always be viewable - overwrites Chris previous to 'stop menu overlapping calendar'
  } */
.MuiIconButton-root:hover {
  background-color: inherit !important;
}
.mobileBookingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
}

.action-bar {
  display: flex;
  width: fit-content;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
  margin: 20px;
  background: #fff;
  align-items: center;
}

.pb-table-header {
  display: 'flex' !important;
  justify-content: 'space-between' !important;
  padding: 20px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border: 1px solid #e0e0e0;
  margin: 9px;
  margin-bottom: -10px;
  background: #fff;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .main {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 768px) {
  .reservation-creation-stepper-header {
    flex-direction: column;
    row-gap: .25rem;
  }

  .reservation-creation-stepper-header .MuiTypography-root:nth-child(1) {
    display: block;
    font-size: 14px;
  }
  .MuiDialog-paper {
    width: 100% !important;
    margin: 0 auto !important;
  }

  .reservation-creation-stepper-header .MuiTypography-root:nth-child(2) {
    margin-left: 0px;
    display: block;
  }
  .reservation-creation-stepper-body {
    padding: 5px;
  }
  .reservation-creation-modal-wrapper {
    padding: 0px;
  }
  .facility-picker-wrapper {
    flex-direction: column;
    gap: .25rem;
  }

  .reservation-creation-timetable {
    flex-direction: column-reverse;
  }
}

@media print {
  .no-print, .no-print * {
    display: none !important;
  }
}