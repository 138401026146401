.wrapper {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.paymentListWrapper {
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 1rem;
}

.alert {
  width: 100%;
  margin-bottom: 1rem;
}

@media (min-width: 768px) {
  .wrapper {
    padding: 0;
  }

  .paymentListWrapper {
    flex-direction: row;
  }
}

