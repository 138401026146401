.container {
  width: 100%;
  padding: 1rem;
  margin-top: 1rem;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  background: white;
  padding: 2rem;
  border: 1px solid #eaeaea;
  border-radius: 0.5rem;
}