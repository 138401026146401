.solutionHeader {
  color: #333;
  margin-top: 100px;
  text-align: center;
  h1 {
    font-size: 36px;
  }
}
.pricingHeader{
  color: #333;
  margin-top: 30px;
  text-align: center;
  h1 {
    font-size: 36px;
  }
}
.testimonialVideoToggle {
  background-color: #333 !important;
  color: #fff !important;
  outline-color: #fff !important;
  outline-width: 1px !important;
  outline-style: solid !important;
}

.solutionSubHeader {
  color: #333;
  width: 60%;
  margin: 0 auto;
  text-align: center;
}
.videoSection {
  padding: 40;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 90%;
}
.solutionSubFeatures {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  width: 65%;
  margin: 0 auto;
  text-align: center;
  flex-wrap: wrap;
  display: flex;
  flex-direction: row; 
}
.solutionSubFeat {
  flex: 1 0 21%;
  padding: 10px;
  font-size: 0.9rem;
}
.solutionFeatures {
  color: #333;
  padding-top: 20px;
  padding-bottom: 45px;
  display: flex;
  width: 65%;
  margin: 0 auto;
  text-align: center;
  flex-wrap: wrap;
}
.solutionFeaturesGrey {
  color: #333;
  padding-top: 20px;
  padding-bottom: 45px;
  display: flex;
  width: 65%;
  margin: 0 auto;
  text-align: center;
  flex-wrap: wrap;
}
.solutionFeaturesBlack {
  color: #fff;
  padding-top: 20px;
  padding-bottom: 45px;
  display: flex;
  margin: 0 auto;
  background-color: #fff;
  border-bottom: 1px solid #fff;
  text-align: center;
  flex-wrap: wrap;
}
.slideSolutionFeaturesBlack {
  display: none;
}
.slick-dots li.slick-active button:before {
  color: #fff;
}
.slick-dots li button:before {
  color: #fff;
}
.slick-slide img {
  display: inherit;
}
.solutionSponsored{
  display: flex;
  text-align: center;
  flex-wrap: nowrap;
  align-items: center;
  width: 65%;
  margin: 0 auto;
  padding: 30px 45px;
  color: #fff;
  border-bottom: 1px solid #fff;
  img{
    padding-right: 40px;
  }
}

.eventsBanner {
  display: flex;
  width: 50%;
  margin: 0 auto;
  text-align: center;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
  background-color: transparent;
  padding: 30px;
  box-shadow: 0 10px 20px -4px rgba(50,50,93,.25),0 6px 12px -6px rgba(0,0,0,.3),0 -4px 12px -4px rgba(0,0,0,.025);
  border-radius: 5px;
  }

  .eventsBannerColumn {
    width: 100%;
    padding: 0;
    margin: 0;
    flex-direction: column;
    display: flex;
  flex-basis: 100%;
  flex: 1;
  }

.featuredByImage{
  width: 300px;
  padding: 20px;
  aspect-ratio: attr(width) / attr(height);
}
.solutionIconFeatures {
  color: #333;
  padding-top: 20px;
  padding-bottom: 45px;
  display: flex;
  width: 65%;
  margin: 0 auto;
  //border-bottom: 1px solid #fff;
  text-align: center;
  flex-wrap: wrap;
  align-items: center;
}
.solutionIconFeaturesGrey {
  color: #333;
  padding-top: 20px;
  padding-bottom: 45px;
  display: flex;
  margin: 0 auto;
  text-align: center;
  flex-wrap: wrap;
  align-items: center;
}
.mapImage {
  overflow: hidden;
}
.solutionFeat {
  flex: 1 0 26%;
  padding: 10px;
  font-size: 0.9rem;
}
.solutionFeatGreen {
  flex: 1 0 26%;
  padding: 5px;
  font-size: 0.9rem;
  border-radius: 5px;
  margin: 8px;
  background-color: #7ce8af;
}
.ctaButton {
  margin: 0 auto;
}
.aboutOverallContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 60%;
  margin: 0 auto;
}
.mapContainer {
  background-color: #333;
  color: #fff;
  display: flex;
  padding-bottom: 80px;
}
.solutionMap {
  // background-image: linear-gradient(to right top, #24dc89, #2ba789, #4cb887, #6dc883, #90d77d, #98da7c, #a0dd7a, #a8e079, #95d97c, #83d27f, #72ca82, #61c284);
  padding: 40px;
  padding-right: 100px;
  margin-top: 100px;
  margin: 100px;
  width: 65%;
}
.solutionPricing {
  color: #333;
  padding: 5px;
  border-radius: 5px;
}
.solutionTestimonialIcon {
  flex: 1 0 10%;
  padding: 10px;
  font-size: 0.9rem;
  vertical-align: middle;
  aspect-ratio: attr(width) / attr(height);
}
.solutionPartnerLogoLink {
  flex: 1 0 10%;
  padding: 10px;
  font-size: 0.9rem;
  transition: transform 0.5s;
  vertical-align: middle;
  aspect-ratio: attr(width) / attr(height);
}

.solutionPartnerLogoLink:hover {
  transform: scale(1.1);
}
.solutionTestimonialText {
  flex: 1 0 45%;
  padding: 15px;
  margin: 10px;
  font-size: 0.9rem;
  color: #fff;
  background-color: #333;
  border: solid 1px #fff;
}
.testimonialPerson{
  border-bottom: 1px solid #777;
  min-height: 30%;
}
.solutionTestimonialTextBlack {
  flex: 1 0 35%;
  padding: 15px;
  border-radius: 5px;
  margin: 10px;
  font-size: 0.9rem;
  color: #fff;
  background-color: #333;
}
.solutionTestimonialQuote {
  font-size: 1.2rem;
}
.doublediagonal {
  margin-top: -1px;
  padding-top: 3em;
  // background-image: linear-gradient(to bottom, #24dc89, #2ba789, #4cb887, #6dc883, #90d77d, #98da7c, #a0dd7a, #a8e079, #95d97c, #83d27f, #72ca82, #61c284);
  background-color: #7ce8af;
  color: #333;
  .solutionFeatures {
    color: #333;
  }
}
.pricingBlock {
  h1 {
    color: #333;
  }
  .solutionFeatures {
    color: #333;
  }
  .solutionSubHeader {
    color: #333;
  }
  .solutionFeatures {
    border: none;
  }
  padding-top: 3em;
  padding-bottom: 3em;
  color: #333;
  background-color: #fff;
}
.solutionCallBack {
  background-color: #7ce8af;
  padding-top: 40px;
  padding-bottom: 80px;
  margin-top: -40px;
}
.testimonialSolution {
  padding-top: 3em;
  h1 {
    color: #fff;
  }
  .solutionSubHeader {
    color: #fff;
  }
  color: #fff;
  background-color: #333;
  margin-bottom: -5px;
}
.jeggies {
  width: 100%;
  height: 100px;
}
.headerIcons {
  height: 50px;
  width: 50px;
}
.solutionWalkthrough {
  background-color: #5c5c5c;
  color: #fff;
  h3 {
    color: #fff;
  }
  .solutionHeader {
    color: #fff;
    margin-top: 0;
  }
  .solutionSubHeader {
    color: #fff;
  }
}
.diagonal {
  display: none;
}
.testimonialFace {
  aspect-ratio: attr(width) / attr(height);
  border-radius: 50%;
  margin-right: 20px;
}
.rugbyTabletImage {
  width: 40%;
  margin: 0 auto;
}
.rugbyTabletImageMobile {
  display: none;
}
.sliderSolutionFeatures {
  display: none;
  width: 200px;
  margin: 0 auto;
  text-align: center;
}
.dashboardSolution {
  margin-left: 20px;
}
.cardPaymentSolution {
  margin-right: 20px;
}
.solution-embed-container {
  position: relative;
  padding-bottom: 19.25%;
  height: 0;
  overflow: hidden;
  max-width: 45%;
  margin: 0 auto;
}
.solution-embed-container iframe,
.solution-embed-container object,
.solution-embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.cta {
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.ctaButtonMobile {
  display: none;
}

@media screen and (max-width: 992px) {
  .solutionTestimonialQuote {
    font-size: 1rem;
  }
}
@media screen and (max-width: 10px) {
  .mapImage {
    display: none;
  }
  .solution-embed-container {
    padding-bottom: 44.25%;
    max-width: 90%;
  }
  .sliderSolutionFeatures {
    display: block;
    width: 80%;
    margin: 0 auto;
    margin-top: 20px;
    text-align: center;
  }
  .aboutOverallContainer {
    display: block;
    margin-top: 20px;
    max-width: 100%;
  }

  .eventsBanner {
    display: flex;
    width: 90%;
    margin: 0 auto;
    text-align: center;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    background-color: transparent;
    padding: 10px;
    box-shadow: 0 10px 20px -4px rgba(50,50,93,.25),0 6px 12px -6px rgba(0,0,0,.3),0 -4px 12px -4px rgba(0,0,0,.025);
    border-radius: 5px;
    }
  .solutionSponsored{
    display: flex;
    text-align: center;
    flex-wrap: wrap;
    align-items: center;
    width: 90%;
    margin: 0 auto;
    padding: 30px 45px;
    color: #fff;
    border-bottom: 1px solid #fff;
    img{
      width:200px;
      margin: 0 auto;
      padding-bottom: 15px;
      padding-right: 0px;
    }
  }
  .solutionSubFeatures {
    display: none;
  }
  .solutionFeaturesBlack {
    display: none;
    background-color: #333;
  }
  .slideSolutionFeaturesBlack {
    display: block;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    color: #fff;
    padding-bottom: 30px;
    background-color: #333;
    border-bottom: 1px solid #fff;
  }
  .cta {
    width: 100%;
    font-size: 20px;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .ctaButton {
    display: none;
  }
  .ctaButtonMobile {
    width:90%;
    margin: auto;
    display: block;
  }
  .solutionMap {
    padding-right: 40px;
    margin: auto;
    width: 100%;
  }
  .diagonal {
    display: block;
    transform: skewY(-4deg);
    background-color: #7ce8af;
    width: 100%;
    height: 23vh;
    margin-top: -80px;
    z-index: -1;
  }
  .doublediagonal {
    margin-top: -30px;
  }
  .solutionHeroImage {
    display: none;
  }
  .rugbyTabletImage {
    display: none;
  }
  .rugbyTabletImageMobile {
    display: block;
    margin-top: -50px;
  }
  .testimonialSolution {
    .solutionFeatures {
      width: 100%;
    }
  }
  .pricingBlock {
    .solutionFeatures {
      width: 100%;
      color: #333;
    }
  }

  .solutionTestimonialTextBlack {
    margin: 0px;
  }
  .featuredByImage{
    width: 200px;
    aspect-ratio: attr(width) / attr(height);
  }

  @media screen and (max-width: 478px) and (min-width: 352px) {
    .solutionPricingText {
      height: 180px;
    }
  }
}
