.bookings-table-section {
    margin: 20px;
}
.bookings-table {
    min-width: 750px;
}
.date-range-picker {
    display: flex;
    min-height: 56px;
    min-width: 300px;
    border-top: 1px solid #c4c4c4;
    border-right: 1px solid #c4c4c4;
    border-bottom: 1px solid #c4c4c4;
    border-radius: 0px 4px 4px 0px;
    margin-left: -3px;
    padding: 3px;
    align-items: center;
}
.date-range-picker:hover {
    border-top: 1px solid #333;
    border-right: 1px solid #333;
    border-bottom: 1px solid #333;
}
/* .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl.MuiInputBase-adornedStart {
    width: 250px;
} */
