.facility-card {
    width: 310px;
    min-height: 350px;
    border-radius: 10px;
    border: 2px solid #e2e2e2;
    margin: 15px;
    /* box-shadow: 0px 0px 4px #404040; */
    box-shadow: 0px 0px 20px -11px rgba(0, 0, 0, 0.75);
    position: relative;
    background-color: #fff;
}

.facility-card-image {
    width: 306px;
    max-height: 200px;
    /* border-radius: 10px; */
    border-radius: 8px 8px 0px 0px;
}

.facility-card-title {
    padding: 10px;
}

.facility-card-subtitle {
    padding: 10px;
    padding-top: 0px;
}

.facility-card-actions {
    display: flex;
    width: 100%;
    bottom: 0;
    position: absolute;
    border-top: 1px solid #e2e2e2;
}

.facility-card-edit-section {
    width: 35%;
    text-align: center;
    margin: 0 auto;
    align-self: center;
}

.facility-card-switch-section {
    width: 45%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    align-self: center;
    border-left: 0.5px solid #e2e2e2;
    border-right: 0.5px solid #e2e2e2;
}

.access-restrictions-switch-section {
    display: block;
    align-items: center;
    text-align: center;
    padding-left: 20px;
}

.facility-card-switch {
    display: flex;
    align-items: center;
}

.facility-card-edit-button {
    margin: 30px;
}

.facility-wrapper {
    margin: 40px 40px 0px 40px;
    display: flex;
}

.facility-image {
    width: 220px;
    height: 111px;
    border-radius: 10px;
}

.facility-details-section-1 {
    width: 300px;
}

.facility-details-section-2 {
    margin-left: 50px;
    width: 45vw;
}

.facility-details {
    display: flex;
    flex-wrap: wrap;
    margin: 32px 0px;
    align-items: center;
}

.facility-save-button {
    width: 200px;
    border-radius: 25px !important;
    margin: 15px !important;
    margin-left: 64px !important;
}

.facility-cancel-button {
    width: 200px;
    background-color: #ee9696 !important;
    border-radius: 25px !important;
    margin: 15px !important;
}
.facility-remove-button {
    background-color: #ee9696 !important;
    border-radius: 25px !important;
    margin: 15px !important;
}

.locationLine {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 20px;
    margin-left: 64px;
}

.locationLine.hidden {
    margin-top: 0;
    margin-bottom: 10px;
}

.locationPin {
    width: 30px;
    height: auto;
    margin: 0 6px;
}

.locationPin.hidden {
    opacity: 0;
}

.facility-image-section {
    padding-top: 32px;
    padding-left: 0px;
}
