.login-root {
    display: flex;
    width: 100%;
    margin: 0 auto;
    height: 100%;
    position:absolute;
}

.login-right-side {
    width:70%;
    color: #fff;
    background-image: url('../images/login-blob.png');
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 100%;
    background-size: 90%;
}
.login-left-side {
    padding-left: 175px;
    padding-right: 40px;
    margin-top: 80px;
    width: 30%;
}
.login-enter-details {
    color: #bcc0cc
}
.login-forgot-password {
    padding-top: 40px;
    color: #546172;
    text-decoration-line: underline;
}

.login-ipad{
    width: 70%;
    display: flex;
    margin: auto;
    margin-top: 20%;
    border-radius: 10px;
}

.login-solution{
    display: flex !important;
    flex-flow: row-reverse;
    margin-right: 10% !important;
    margin-top: 23% !important;
    text-decoration-line: underline;
    color: #bcc0cc;
}

.access-denied-dialog{
    text-align: center;
    margin: 0 auto;
    width: 500px;
}

.denied-img{
    margin: auto;
    margin-top: 30px;
    width: 20%;
}

.forgotten-password-dialog{ 
    text-align: center;
    margin: auto;
    width: 500px; 
}

.login-root .MuiDialogActions-root {
    margin-top: 60px;
    margin-bottom: 40px;
}

.login-pb-manager-logo{
    width: 60%;
    margin-bottom: 110px;;

}

.new-password-dialog{
    width: 500px;
    text-align: center;
    margin: 0 auto;
    padding: 0;
}

.referralsBanner {
    display: flex;
    width: 50%;
    margin: 0 auto;
    text-align: center;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: row;
    background-color: white;
    padding: 30px;
    box-shadow: 0 10px 20px -4px rgba(50,50,93,.25),0 6px 12px -6px rgba(0,0,0,.3),0 -4px 12px -4px rgba(0,0,0,.025);
    border-radius: 5px;
    }
  
    .referralsBannerColumn {
      width: 100%;
      padding: 0;
      margin: 0;
      flex-direction: column;
      display: flex;
    flex-basis: 100%;
    flex: 1;
    }

@media screen and (max-width: 1024px) {

    .login-right-side {
        width:70%;
        color: #fff;
        background-image: none;
        background-repeat: no-repeat;
        background-position-x: 100%;
        background-position-y: 100%;
        background-size: 90%;
    }
    .login-left-side {
        padding-left: 115px;
        padding-right: 40px;
        margin-top: 40px;
        width: 30%;
    }
    .login-pb-manager-logo {
        margin-bottom: 50px;
    }
    .login-ipad {
        width: 70%;
        margin-top: 40%;
        border-radius: 10px;
        
    }
}

@media screen and (max-width: 645px) {
    .login-root {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin: 0 auto;
        height: 100%;
        position:absolute;
    }

    .login-right-side {
        width:100%;
        color: #fff;
        background-image: none;
        background-repeat: no-repeat;
        background-position-x: 100%;
        background-position-y: 100%;
        background-size: 90%;
    }
    .login-left-side {
        padding-left: 115px;
        padding-right: 40px;
        margin-top: 40px;
        width: 100%;
    }
    .login-pb-manager-logo {
        margin-bottom: 50px;
    }
    .login-ipad {
        display: none;
        width: 50%;
        margin-top: 40%;
        border-radius: 10px;
    }
}
@media screen and (max-width: 375px) {
    .login-root {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin: 0 auto;
        height: 100%;
        position:absolute;
    }

    .login-right-side {
        width:100%;
        color: #fff;
        background-image: none;
        background-repeat: no-repeat;
        background-position-x: 100%;
        background-position-y: 100%;
        background-size: 90%;
    }
    .login-left-side {
        padding-left: 30px;
        padding-right: 40px;
        margin-top: 40px;
        margin-bottom: 100px;
        width: 100%;
    }
    .login-pb-manager-logo {
        margin-bottom: 50px;
    }
    .login-ipad {
        display: none;
        width: 50%;
        margin-top: 40%;
        border-radius: 10px;
    }
}