.referral__wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
}

.referral__information {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.referral__separator {
  background-color: rgba(0, 0, 0, 0.1);
  height: 1px;
  width: 100%;
}

.referral__form {
  
}

.referral__history {
  
}