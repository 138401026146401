.pb-flex {
  display: flex;
}

.pb-flex-col {
  flex-direction: column;
}

.pb-space-between {
  justify-content: space-between;
}

.pb-gap-1 {
  gap: 0.25rem;
}

.pb-gap-2 {
  gap: 0.5rem;
}

.pb-gap-3 {
  gap: 0.75rem;
}

.pb-gap-4 {
  gap: 1rem;
}

.pb-gap-5 {
  gap: 1.5rem;
}

.pb-gap-6 {
  gap: 2rem;
}

.pb-mb-1 {
  margin-bottom: 0.25rem;
}

.pb-mb-2 {
  margin-bottom: 0.5rem;
}

.pb-mb-3 {
  margin-bottom: 0.75rem;
}

.pb-mb-4 {
  margin-bottom: 1rem;
}

.pb-mb-5 {
  margin-bottom: 1.5rem;
}

.pb-mb-6 {
  margin-bottom: 2rem;
}

.pb-mt-1 {
  margin-top: 0.25rem;
}

.pb-mt-2 {
  margin-top: 0.5rem;
}

.pb-mt-3 {
  margin-top: 0.75rem;
}

.pb-mt-4 {
  margin-top: 1rem;
}

.pb-mt-5 {
  margin-top: 1.5rem;
}

.pb-mt-6 {
  margin-top: 2rem;
}

.pb-font-size-1 {
  font-size: 0.8rem;
}

.pb-font-size-2 {
  font-size: 0.9rem;
}

.pb-font-size-3 {
  font-size: 1rem;
}

.pb-font-semibold {
  font-weight: 600;
}

.pb-text-muted {
  color: rgba(0,0,0,0.4);
}