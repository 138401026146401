.CalendarHeader {
  width: 120px;
  padding-top: 20px;
  height: 90px;
  border-bottom: 0.5px solid #e0e0e0;
  border-top: 0.5px solid #e0e0e0;
  border-left: 0.5px solid #e0e0e0;
  padding-left: 8px;
  display: inline-block;
  vertical-align: top;
  background-color: #ffffff;
  text-align: left;
}

.calendar-spare {
  width: 190px;
  padding-top: 20px;
  position: sticky;
  left: 0;
  height: 90px;
  border-bottom: 0.5px solid #e0e0e0;
  padding-left: 8px;
  display: inline-block;
  vertical-align: top;
  background-color: #fafbff;
}

.CalendarFacilityHeader {
  width: 190px;
  padding-top: 20px;
  position: sticky;
  left: 0;
  height: 90px;
  border-top: 0.5px solid #e0e0e0;
  border-bottom: 0.5px solid #e0e0e0;
  border-left: 0.5px solid #e0e0e0;
  padding-left: 8px;
  display: inline-block;
  vertical-align: top;
  background-color: #ffffff;
}
.calendar-parent-facility-header {
  position: sticky;
  display: inline-block;
  vertical-align: top;
  left: 0;
  width: 190px;
  height: 90px;
  font-weight: 700;
  font-size: 14px;
  padding-left: 10px;
  border-bottom: 0.5px solid #e0e0e0;
  border-left: 0.5px solid #e0e0e0;
  background-color: #fff;
}
.calendar-sub-facility-header {
  position: sticky;
  display: inline-block;
  vertical-align: top;
  left: 0;
  width: 190px !important;
  height: 90px;
  padding-left: 30px;
  padding-right: 5px;
  border-bottom: 0.5px solid #e0e0e0;
  border-left: 0.5px solid #e0e0e0;
  background-color: #fafbff;
}

.CalendarHeaderSub {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
}

.CalendarHeaderSub-2 {
  color: #a1aac7;
}

.hour {
  width: 10px;
  height: 90px;
  /* border-top: 0.5px solid #e0e0e0; */
  border-bottom: 0.5px solid #e0e0e0;
  /* padding: .5em; */
  align-items: center;
  text-align: left;
  display: inline-block;
  vertical-align: top;
  background-color: #ffffff;
}

.onHour {
  width: 10px;
  height: 90px;
  border-left: 0.5px solid #e0e0e0;
  /* border-top: 0.5px solid #e0e0e0; */
  border-bottom: 0.5px solid #e0e0e0;
  /* padding: .5em; */
  align-items: center;
  text-align: left;
  display: inline-block;
  vertical-align: top;
  background-color: #fff;
}
.hiddenHour {
  width: 0;
  height: 90px;
  /* border-top: 0.5px solid #e0e0e0; */
  border-bottom: 0.5px solid #e0e0e0;
  align-items: center;
  text-align: center;
  display: inline-block;
  vertical-align: top;
  background-color: #ffffff;
}
.keys {
  margin-right: 10px;
  margin-left: 30px;
  width: 20px;
  height: 20px;
  border-radius: 5px;
}
.bookingsKey {
  background: linear-gradient(
    145deg,
    rgba(191, 251, 211, 0.3) 0%,
    rgba(243, 255, 247, 0.3) 100%
  );
  border: 3px solid #1dcc7d;
}
.subscriptionKey {
  border: 3px solid rgb(113, 208, 255);
}
.membershipKey {
  background: linear-gradient(
    rgba(192, 144, 221, 0.3) 0%,
    rgba(228, 197, 247, 0.3) 100%
  );
}
.restrictedAccessKey {
  background-color: #e0e0e0;
}
.unpaidKey {
  background: linear-gradient(
    145deg,
    rgba(255, 186, 186, 0.3) 0%,
    rgba(255, 221, 221, 0.3) 100%
  );
  border: 3px solid #ac372f;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  border: #4581e2;
  background-color: #4581e2;
}
.DateInput_input__focused {
  border-bottom: 2px solid #4581e2;
}
.DateInput_input {
  text-align: center;
  padding: 0px;
}
.DateInput {
  width: 160px;
}
.SingleDatePickerInput_calendarIcon {
  margin-top: 13px;
}
.SingleDatePicker {
  z-index: 2;
}
.DateInput {
  width: 200px !important;
}
.DayPickerKeyboardShortcuts_show__bottomRight::before {
  border-right: 33px solid #4581e2;
}
.MuiInputBase-root {
  width: 300px;
}
.MuiInput-underline:after {
  border-bottom: 2px solid #4581e2 !important;
}
.calendar-1 {
  overflow-x: scroll;
  display: inline-block;
  margin-top: 40px;
}

.fixed_header {
  margin-top: 40px;
  width: 1560px;
  overflow: scroll;
  display: block;
}

.td {
  background-color: white;
  width: 45px;
  height: 90px;
  float: left;
}
.th {
  background-color: white;
  width: 120px;
  height: 90px;
  float: left;
}

.thead {
  position: sticky;
  top: 0px;
  z-index: 1;
}

.th {
  font-weight: bold;
}

.tr {
  display: block;
  clear: both;
}

.thead > div > div:nth-child(1) {
  left: 0;
  top: 0;
  position: sticky;
}

@page {
  size: landscape;
}
@media print {
  body {
    -webkit-print-color-adjust: exact;
  }
  .fixed_header {
    width: auto;
    height: auto;
    zoom: 65%;
  }
}
