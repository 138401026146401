/* 
    This is the Analytics style sheet.
    
    It emphasizes a MOBILE-FIRST design
    scheme. Adjustments for tablet, laptop,
    and desktop are made at the bottom of
    the file.

    NOTE 1: Almost all styling for the manager
    analytics is kept in this file, as opposed
    to being in an individual component's file.
    The exceptions to this rule are when dealing
    with MUI components.

    NOTE 2: The chartContainer's position must
    be set to RELATIVE in order for the Chart.js
    graph to adjust its size as the screen size
    changes. See the following link for more info:
    https://www.chartjs.org/docs/latest/general/responsive.html
*/

/* ANALYTICS */
.analytics {
    align-items: center;
    background-color: #fafbff;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

/* ALERT STYLING */
#alert {
    margin-top: 20px;
    width: 96%;
}

/* HEADER STYLING */
.categoryHighlight {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.eventCardImage {
    border-radius: 10px;
    margin: 10px;
    height: 100px;
    width: 156px;
}

#headerHighlights {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.headerRow {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-bottom: 10px;
    padding-top: 10px;
    width: 100%;
}

.headerItem {
    align-items: center;
    background-color: #ffffff;
    border-radius: 5px;
    display: flex;
    height: 100px;
    justify-content: space-around;
    margin: 10px 0;
    padding: 10px;
    width: 90%;
}

.iconContainer {
    align-items: center;
    background-color: #32cd3210;
    border-radius: 5px;
    display: flex;
    height: 75%;
    justify-content: center;
    padding: 5px 20px;
}

.percentChangeUp {
    color: green;
    font-size: 3vw;
}

.percentChangeDown {
    color: red;
    font-size: 3vw;
}

#settingsButton {
    background-color: #00000080;
    border-color: transparent;
    border-radius: 20px;
    color: #ffffff;
    margin-top: 15px;
    min-width: 125px;
}

.settingsWrapper {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 90%;
}

.statistic {
    align-items: flex-start;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    font-size: 3vw;
    justify-content: center;
    height: 100%;
    padding: 10px;
    width: 100%;
}

.statisticContent {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.statisticLabel {
    color: #00000080;
    font-size: 1.5vw;
}

.timeframeLabel {
    background-color: #00000040;
    border-color: transparent;
    border-radius: 5px;
    color: #ffffff;
    padding: 5px 10px;
    text-align: center;
}

/* MODAL STYLING */
.dateButton {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.popularTimeframesContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.settingsModalCard {
    align-items: flex-start;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: center;
    padding: 10px 0;
    position: relative;
    width: 100%;
}

#settingsModalCardEvents {
    padding-bottom: 0;
    border-bottom: none;
}

#endDate,
#startDate {
    padding: 0;
}

/* CATEGORY STYLING*/
.category {
    align-items: center;
    background-color: #ffffff;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 50vh;
    margin: 10px 0;
    padding: 10px;
    transition: all 500ms ease;
    width: 90%;
}

.categoriesWrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 96%;
}

.categoryHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.categoryLabel {
    color: #00000080;
    font-size: 20px;
    padding: 5px 10px;
    text-align: center;
    min-width: 125px;
}

.chartContainer {
    align-items: center;
    display: flex;
    height: 85%;
    justify-content: center;
    position: relative;
    width: 95%;
}

#Dropdown {
    display: flex;
    margin-bottom: auto;
    padding:5px;
    width: 70%;
}

/* Utilisation */
.UtilisationChart{
    margin-top: 15px;
}

#Utilisation{
    height: 100%;
}

.utilisationStats{
    width: 80%;
}

.utilisationInfo{
    flex-direction: column;
    width: 100%;
    align-items: center;
}

#headerEventUsage{
    width: 85%;
    text-align : center;
}

#eventStats{
    border-style: solid;
    border-width: 1px;
    border-color: black;
    text-align: center;
}

.Stats{
    flex-direction: row;
    width: 80%;
}

.square{
    margin-right: 4px;
}

/* LARGE SCREEN ADJUSTMENTS */
@media screen and (min-width: 975px) {
    /* HEADER ADJUSTMENTS */
    .categoryHighlight {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }

    #headerHighlights {
        flex-direction: row;
        justify-content: space-between;
        width: 500px;
    }

    .headerItem {
        margin-right: 10px;
    }

    .headerRow {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 96%;
    }

    .percentChangeDown {
        font-size: 14px;
    }

    .percentChangeUp {
        font-size: 14px;
    }

    #settingsButton {
        margin-top: auto;
    }

    .settingsWrapper {
        width: auto;
    }

    .statistic {
        font-size: 20px;
    }

    .statisticLabel {
        font-size: 14px;
    }

    #headerEventUsage{
        width: 110%;
        padding-top: 0px;
        padding-bottom: 0px;
    }

    /* CATEGORY ADJUSTMENTS */
    .category {
        /*
            This might need to be adjusted back
            depending on the addition of further
            categories such as utilisation &
            lead-time.
        */
        height: 65vh;
        width: 49%;
    }

    .categoriesWrapper {
        flex-direction: row;
        flex-wrap: wrap;
        padding-bottom: 5vh;
    }

    .categoryLabel {
        font-size: 25px;
        padding: 10px 20px;
    }

    .utilisationInfo{
        width: 85%;
        height: 85%;
        flex-direction: row;
        align-items: start;
    }

    .UtilisationChart{
        margin-top: 0px;
        align-items: end;
        height: 100%;
        flex-direction: row;
    }

    #headerEventUsage{
        width: 125%;
        padding: 0px;
    }

    .utilisationStats{
        width: 30%;
    }

    #Utilisation{
        height: 65vh;
    }
    #Dropdown{
        width: 95%;
    }
    .legend{
        margin-bottom: 12px;
    }

    .Stats{
        flex-direction: column;
        width: 22%;
    }

    /* MODAL ADJUSTMENTS */
    #settingsModalCardEvents {
        height: auto;
    }
}
